/* istanbul ignore file */

import {
  Box,
  Flex,
  Text,
  TertiaryButton,
  Heading,
} from '@moonpig/launchpad-components'
import { IconSystemChevronRight } from '@moonpig/launchpad-assets'
import { useTrackGAEventOnce } from '@moonpig/web-core-analytics'
import { system as s } from '@moonpig/launchpad-system'
import { breakpointUp, breakpointDown } from '@moonpig/launchpad-utils'
import React, { FC } from 'react'
import styled from 'styled-components'
import { PromoTileCta } from '../../types'
import { SponsoredPill } from '../SponsoredPill'
import { getProductImageDimensions } from '../../utils/getProductImageDimensions'
import { STANDARD_CARD_SIZE_ID } from '../../constants'

const StyledBox = styled(Box)<{
  isCardGallery: boolean
  paddingBottom: number
}>`
  display: ${props => (props.isCardGallery ? null : 'flex')};
  flex-direction: ${props => (props.isCardGallery ? null : 'column')};
  height: ${props => (props.isCardGallery ? null : '100%')};
  position: relative;
  min-height: 200px;

  &:before {
    ${breakpointUp('lg')} {
      display: ${props => (props.isCardGallery ? 'block' : null)};
      height: ${props => (props.isCardGallery ? '0' : null)};
      content: '';
      padding-bottom: ${props =>
        props.isCardGallery ? `calc(${props.paddingBottom}% - 36px)` : null};
    }

    ${breakpointUp('xxl')} {
      padding-bottom: ${props =>
        props.isCardGallery ? `calc(${props.paddingBottom}% - 90px)` : null};
    }
  }
`

const StyledAdBox = styled(Box)<{ isCardGallery: boolean }>`
  ${s({
    borderRadius: 2,
  })}

  position: relative;
  left: ${props => (props.isCardGallery ? '8px' : null)};
  width: ${props => (props.isCardGallery ? 'calc(100% - 16px)' : '100%')};
  height: ${props => (props.isCardGallery ? 'calc(100% - 16px)' : '100%')};
  overflow: hidden;

  ${breakpointUp('lg')} {
    position: ${props => (props.isCardGallery ? 'absolute' : null)};
    top: ${props => (props.isCardGallery ? '4px' : null)};
  }

  ${breakpointUp('xxl')} {
    top: ${props => (props.isCardGallery ? '34px' : null)};
    left: ${props => (props.isCardGallery ? '32px' : null)};
    width: ${props => (props.isCardGallery ? 'calc(100% - 64px)' : null)};
  }
`

const Wrapper = styled.div<{ isCardGallery: boolean }>`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  ${breakpointUp('lg')} {
    top: ${props => (props.isCardGallery ? '100%' : null)};
    transform: ${props => (props.isCardGallery ? 'translateY(-100%)' : null)};
  }
`

const StyledCallToAction = styled(TertiaryButton)`
  text-decoration: underline;
`

const BorderBoxDesktop = styled(Box)`
  ${s({
    paddingLeft: 6,
    borderTop: 1,
    borderColor: 'colorBorder04',
  })}

  ${breakpointDown('lg')} {
    display: none;
  }

  ${breakpointUp('xxl')} {
    ${s({
      paddingLeft: 8,
    })}
  }
`

const BorderBoxMobile = styled(Box)`
  ${s({
    paddingLeft: 6,
    borderColor: 'colorBorder04',
    borderTop: 1,
  })}

  ${breakpointUp('lg')} {
    display: none;
  }
`

const ImageWrapper = styled.div<{ isCardGallery: boolean; image: string }>`
  position: relative;
  width: ${props =>
    props.isCardGallery ? 'calc(50% - 16px)' : 'calc(50% - 8px)'};
  flex-shrink: 0;
  overflow: hidden;
  background: ${props => `url(${props.image}) 50% 50%`};
  background-size: cover;

  ${breakpointUp('md')} {
    width: ${props =>
      props.isCardGallery ? 'calc(33.333% - 24px)' : 'calc(33% - 8px)'};
  }

  ${breakpointUp('lg')} {
    width: ${props =>
      props.isCardGallery ? 'calc(50% - 24px)' : 'calc(50% - 8px)'};
  }
`

const ContentWrapper = styled(Flex)<{ isCardGallery: boolean }>`
  min-height: 200px;

  ${breakpointDown('lg')} {
    min-height: ${props => (props.isCardGallery ? '280px' : '400px')};
  }
`

type PromoTileProps = {
  title: string
  description: string
  sponsored: boolean
  image: {
    src: string
    description: string | undefined
  }
  cta: PromoTileCta | undefined
  isCardGallery: boolean
}

const calculatePaddingBottom = () => {
  const dimensions = getProductImageDimensions(
    true,
    STANDARD_CARD_SIZE_ID,
    false,
  )

  return (dimensions.height / (2 * dimensions.width)) * 100
}

export const PromoTile: FC<PromoTileProps> = ({
  title,
  description,
  sponsored,
  image,
  cta,
  isCardGallery,
}) => {
  const { trackGAEventOnce } = useTrackGAEventOnce()
  const trackClick = () =>
    trackGAEventOnce({
      event: 'select_content',
      content_data: {
        content_type: `search | sponsored tile click | ${cta?.title.toLowerCase()}`,
      },
      // For GA3 backward compatibility
      event_data: {
        category: 'search',
        action: 'select sponsored tile',
        label: `${cta?.url} | ${cta?.title.toLowerCase()}`,
        non_interaction: true,
        value: undefined,
      },
      // Web only //
      discount_data: undefined,
      ecommerce: undefined,
      error_data: undefined,
    })

  const handleClick = () => {
    trackClick()

    if (cta?.onClick) {
      cta.onClick()
    }
    if (cta?.url && !cta?.onClick) {
      window.location.href = cta?.url
    }
  }

  return (
    <Box
      tabIndex={0}
      aria-label={'sponsored product tile'}
      data-testid="promo-tile-container"
    >
      <StyledBox
        isCardGallery={isCardGallery}
        paddingBottom={calculatePaddingBottom()}
      >
        <StyledAdBox boxShadow={2} isCardGallery={isCardGallery}>
          <Wrapper isCardGallery={isCardGallery}>
            <Flex flexDirection={'row'} flex={1}>
              <ImageWrapper
                aria-describedby={image.description}
                isCardGallery={isCardGallery}
                image={image.src}
                data-testid="promo-tile-image"
              />
              <ContentWrapper
                isCardGallery={isCardGallery}
                flexDirection={'column'}
                justifyContent={'space-between'}
              >
                <Box
                  padding={{
                    xs: 6,
                    xxl: 8,
                  }}
                >
                  {sponsored && (
                    <Box
                      alignSelf="top"
                      mb={{
                        xs: 4,
                        md: 6,
                      }}
                    >
                      <SponsoredPill />
                    </Box>
                  )}
                  <Box height={'100%'}>
                    <Box>
                      <Heading
                        typography={'typeBodyLabel'}
                        level={'h4'}
                        mb={3}
                        tabIndex={0}
                      >
                        {title}
                      </Heading>
                    </Box>
                    <Box>
                      <Text
                        typography={'typeBodyCaption'}
                        alignSelf="top"
                        tabIndex={0}
                        as={'p'}
                        mb={0}
                      >
                        {description}
                      </Text>
                    </Box>
                  </Box>
                </Box>
                <BorderBoxDesktop>
                  <StyledCallToAction
                    trailingIcon={<IconSystemChevronRight />}
                    data-testid={'ad-tile-cta-desktop'}
                    onClick={handleClick}
                  >
                    {cta?.title}
                  </StyledCallToAction>
                </BorderBoxDesktop>
              </ContentWrapper>
            </Flex>
            <BorderBoxMobile>
              <StyledCallToAction
                trailingIcon={<IconSystemChevronRight />}
                data-testid={'ad-tile-cta-mobile'}
                onClick={handleClick}
              >
                {cta?.title}
              </StyledCallToAction>
            </BorderBoxMobile>
          </Wrapper>
        </StyledAdBox>
      </StyledBox>
    </Box>
  )
}
