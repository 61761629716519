import React from 'react'
import { Box, Flex, PrimaryButton, Text } from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'
import { IconSystemFilter } from '@moonpig/launchpad-assets'
import { useExperimentString } from '@moonpig/web-core-eppo/browser'
import { FILTER_COUNT_SIZE_PX } from '../../../FiltersCarousel/constants'
import { useFindLocaleText } from '../../../../text-localisation'
import { styledToggleStyles, styledToggleVariantStyles } from './styles'

const StyledToggleWrapper = styled(Flex)<{
  useDropdownFilters: boolean
}>`
  ${({ useDropdownFilters }) =>
    s({
      pl: { xs: 6, md: 8, lg: 10, xxl: 12 },
      pr: useDropdownFilters ? 0 : 4,
      py: 0,
      borderRight: useDropdownFilters ? 0 : 1,
      borderColor: 'colorBlack10',
    })}
`

const StyledToggle = styled(PrimaryButton)<{
  useDropdownFilters: boolean
  filtersApplied: boolean
}>`
  ${({ useDropdownFilters }) =>
    useDropdownFilters ? styledToggleVariantStyles : styledToggleStyles}
`

const StyledFilterCount = styled.div<{ useDropdownFilters: boolean }>`
  width: ${FILTER_COUNT_SIZE_PX};
  height: ${FILTER_COUNT_SIZE_PX};
  ${({ useDropdownFilters }) =>
    s({
      borderRadius: 7,
      bgcolor: useDropdownFilters
        ? 'colorInteractionSelectedState'
        : 'colorInteractionButton',
      color: 'colorTextInverted',
      typography: 'typeButtonLabel',
    })}
`

type ToggleProps = {
  onClick: () => void
  appliedFiltersCount: number
  isOpen: boolean
  ref?: React.Ref<HTMLAnchorElement>
}

export const Toggle = React.forwardRef<HTMLAnchorElement, ToggleProps>(
  ({ onClick, appliedFiltersCount, isOpen }, ref) => {
    const localiseText = useFindLocaleText()
    const useDropdownFilters =
      useExperimentString('search-use-dropdown-filters-v2', {
        fallback: 'disabled',
      })?.toLowerCase() === 'enabled'

    const filtersApplied = appliedFiltersCount > 0

    const createLeadingIcon = () => {
      return filtersApplied ? (
        <StyledFilterCount
          useDropdownFilters={useDropdownFilters}
          aria-label={localiseText('find.n_filters_applied', {
            filterCount: appliedFiltersCount,
          })}
        >
          {appliedFiltersCount}
        </StyledFilterCount>
      ) : (
        <Box data-testid={'filters-menu-icon'}>
          <IconSystemFilter aria-hidden width="24px" />
        </Box>
      )
    }

    return (
      <StyledToggleWrapper useDropdownFilters={useDropdownFilters}>
        <StyledToggle
          ref={ref}
          aria-label={localiseText('find.open_filters')}
          aria-expanded={isOpen}
          aria-controls="filters-menu"
          data-testid="filters-menu-toggle"
          onClick={onClick}
          leadingIcon={createLeadingIcon()}
          useDropdownFilters={useDropdownFilters}
          filtersApplied={filtersApplied}
        >
          <Text typography="typeButtonLabel">
            {localiseText('find.filters')}
          </Text>
        </StyledToggle>
      </StyledToggleWrapper>
    )
  },
)
