import { TilesPerRow } from '../../types'

export const getTilesPerRow = (breakpoint: keyof TilesPerRow) => {
  const tilesPerRow: TilesPerRow = {
    xs: 2,
    sm: 2,
    md: 3,
    lg: 4,
    xl: 5,
    xxl: 6,
  }
  const currentTilesPerRow = tilesPerRow[breakpoint]
  return currentTilesPerRow
}
