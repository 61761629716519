import React, { FC, useState } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { spacingPx } from '@moonpig/launchpad-theme'
import {
  CarouselRoot,
  CarouselControls,
  CarouselState,
  CarouselPreviousButton,
  CarouselNextButton,
  UnstyledCarouselScrollArea,
  Flex,
  Box,
} from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { useExperimentString } from '@moonpig/web-core-eppo/browser'
import { useFindLocaleText } from '../../text-localisation'
import { useSearchStore } from '../../store/SearchStore'
import {
  FiltersCarouselStickyWrapper,
  CarouselItem,
  FiltersMenuButton,
  AllFiltersButton,
} from './components'
import { createCarouselFilterItems } from './createCarouselFilterItems'
import { useNbaFilters } from '../../utils/nbaFilters'

/* istanbul ignore next */
const calculatePaddingLeft = ({
  useDropdownFilters,
}: {
  useDropdownFilters: boolean
}) => {
  if (useDropdownFilters) {
    return { xs: 3, md: 4 }
  }
  if (useDropdownFilters) {
    return 0
  }
  return 0
}
/* istanbul ignore next */
const StyledFlex = styled(Flex)<{
  useDropdownFilters: boolean
}>`
  ${({ useDropdownFilters }) =>
    s({
      width: { xs: '100vw', md: '100%' },
      maxWidth: '100%',
      flexDirection: 'column',
      py: { xs: 3, md: 4 },
      mb: useDropdownFilters ? 0 : 6,
      pl: calculatePaddingLeft({ useDropdownFilters }),
      pr: 0,
      bgcolor: 'colorBackground01',
      borderBottom: useDropdownFilters ? 1 : 0,
      borderColor: 'colorBorder04',
    })}
`

const StyledCarouselRoot = styled(CarouselRoot)`
  ${s({
    px: 0,
  })}
`

const StyledCarouselScrollArea = styled(UnstyledCarouselScrollArea)`
  gap: ${spacingPx(4)};
`

export const FiltersCarousel: FC = () => {
  const filtersfromStore = useSearchStore(store => store.filters)
  const filters = useNbaFilters(filtersfromStore || [])

  const useDropdownFilters =
    useExperimentString('search-use-dropdown-filters-v2', {
      fallback: 'disabled',
    })?.toLowerCase() === 'enabled'

  const localiseText = useFindLocaleText()
  const [filtersButtonInView, setFiltersButtonInView] = useState(false)

  const toggleFiltersMenu = useSearchStore(store => store.toggleFiltersMenu)
  const filtersMenuOpen = useSearchStore(store => store.filtersMenuOpen)
  const resultsLoading = useSearchStore(store => store.loading)
  const selectedFilters = useSearchStore(store => store.selectedFilters)
  const optimisticRender = useSearchStore(store => store.optimisticRender)
  const carouselItems = [
    <CarouselItem key="open-filters-button">
      <FiltersMenuButton
        onInView={setFiltersButtonInView}
        toggleMenu={toggleFiltersMenu}
        isMenuOpen={filtersMenuOpen}
        appliedFilters={selectedFilters}
      />
    </CarouselItem>,
    ...createCarouselFilterItems({
      filters,
      resultsLoading,
      selectedFilters,
      optimisticRender,
      localiseText,
      useDropdownFilters,
    }),
  ]

  if (!useDropdownFilters) {
    carouselItems.push(
      <CarouselItem key="view-all-filters-button">
        <AllFiltersButton
          displayButton={
            !filtersMenuOpen && !resultsLoading && !filtersButtonInView
          }
          onFiltersClickedCallback={toggleFiltersMenu}
        />
      </CarouselItem>,
    )
  }

  return (
    <FiltersCarouselStickyWrapper>
      <StyledFlex
        data-testid="filters-carousel-variant"
        useDropdownFilters={useDropdownFilters}
      >
        {carouselItems.length > 0 ? (
          <CarouselState>
            <StyledCarouselRoot aria-label={localiseText('find.nba_filters')}>
              <Box display={{ xs: 'none', md: 'block' }}>
                <CarouselControls>
                  <CarouselPreviousButton />
                  <CarouselNextButton />
                </CarouselControls>
              </Box>
              <StyledCarouselScrollArea>
                {carouselItems}
              </StyledCarouselScrollArea>
            </StyledCarouselRoot>
          </CarouselState>
        ) : /* istanbul ignore next */ null}
      </StyledFlex>
    </FiltersCarouselStickyWrapper>
  )
}
