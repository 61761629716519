import React, { useRef, useEffect, useMemo, FC } from 'react'
import { Grid, Box, AspectRatio } from '@moonpig/launchpad-components'
import { logger } from '@moonpig/web-core-monitoring'
import { Region } from '@moonpig/web-core-types'
import { useRouter } from '@moonpig/web-core-routing'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { DepartmentsEnum } from '@moonpig/web-shared-types-graphql/graphqlTypes'
import { getParentDepartment } from '@moonpig/web-core-utils'
import { useExperimentString } from '@moonpig/web-core-eppo/browser'
import { TrackedProductTile } from '../ProductTile/TrackedProductTile'
import { ProductTileProduct } from '../ProductTile/ProductTileProduct'
import {
  ProductClickedEvent,
  AddedToBasketEvent,
  PromoTileData,
  TilesPerRow,
} from '../../types'
import { checkFavourited, useFavouritesState } from '../../contexts/favourites'
import { useHandleFavourite } from '../../utils/favourites/useHandleFavourite'
import {
  AD_ROW,
  AD_CELL_WIDTH,
  AD_CELL_WIDTH_WIDE,
} from '../PromoTile/constants'
import {
  DESKTOP_ROW_POSITION,
  MOBILE_ROW_POSITION,
} from '../CustomerSatisfactionTile/constants'
import {
  calculateComponentWidth,
  calculateCsatPosition,
  formatGridLayout,
} from './utils'
import { GalleryProduct, ProductGridComponent, ProductGridItem } from './types'
import { PromoTile } from '../PromoTile'
import { PromoTileVariant } from '../PromoTileVariant'
import { useNumberOfTilesPerRow } from '../../utils/useNumberOfTilesPerRow'
import { CustomerSatisfactionTile } from '../CustomerSatisfactionTile'

const GALLERY_PAGE_SIZE = 24

type ProductGridProps = {
  products: GalleryProduct[]
  totalNumberOfProducts: number
  onEndApproaching: () => void
  onTopApproaching: () => void
  onOffsetBorderApproaching: (productIndex: number) => void
  onProductClicked: (clickedEvent: ProductClickedEvent) => void
  onProductFirstClick: (clickedEvent: ProductClickedEvent) => void
  onAddToBasket: (addToBasketEvent: AddedToBasketEvent) => void
  isInternalUser?: boolean
  currentPage?: number
  departments: DepartmentsEnum[]
  trackingData: {
    region: Region
    pageType: string
    metaTitle?: string
  }
  groupCardProject?: string
  promoTile?: PromoTileData
}

const StyledGrid = styled(Grid)<{ shouldShowNewGridLayout: boolean }>`
  ${({ shouldShowNewGridLayout }) =>
    s({
      mt: 5,
      paddingX: {
        xs: 4,
        md: /* istanbul ignore next */ shouldShowNewGridLayout ? 6 : 8,
      },
    })}
`
export const ProductGrid: FC<ProductGridProps> = ({
  products,
  totalNumberOfProducts,
  onProductClicked,
  onProductFirstClick,
  onAddToBasket,
  onEndApproaching,
  onTopApproaching,
  onOffsetBorderApproaching,
  isInternalUser = false,
  departments,
  trackingData,
  groupCardProject,
  promoTile,
}) => {
  const { favourites: favouritedProducts } = useFavouritesState()
  const duplicates = useRef<string[]>([])
  const lastVisitedProduct = useRef<HTMLDivElement | null>(null)
  const productIds = products.map(product => product.id)
  const router = useRouter()
  const showCardProductTileVariant =
    useExperimentString('search-product-tile-ux', {
      fallback: 'disabled',
    })?.toLowerCase() === 'enabled'

  const ShowCSATTile =
    useExperimentString('search-use-csat-tile', {
      fallback: 'disabled',
    })?.toLowerCase() === 'enabled'
  const { params } = router.getCurrentRoute<'content'>()
  const { productId: lastVisitedProductId } = params

  const parentDepartment = getParentDepartment(departments[0])
  const isCard = parentDepartment === 'ALL_CARDS'

  const shouldShowNewGridLayout = showCardProductTileVariant && isCard

  const tilesPerRow: TilesPerRow = {
    xs: 2,
    sm: 2,
    md: 3,
    lg: 4,
    xl: 5,
    xxl: 6,
  }

  const tracking = useMemo(() => {
    return {
      totalCount: products.length,
      pageLocation: trackingData.pageType,
    }
  }, [products.length, trackingData.pageType])

  const handleFavourite = useHandleFavourite({
    tracking,
    removeWithConfirmation: false,
  })

  useEffect(() => {
    const productMarker = lastVisitedProduct.current
    if (productMarker && lastVisitedProductId) {
      const productElement = productMarker.parentElement as HTMLElement
      productElement.scrollIntoView({ block: 'center' })
      lastVisitedProduct.current = null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const currentDuplicates = productIds.filter(
    (item, index) => productIds.indexOf(item) !== index,
  )

  if (duplicates.current.length < currentDuplicates.length) {
    duplicates.current = currentDuplicates
    logger.warning(
      `Product Grid: Duplicate items found (page ${Math.ceil(
        products.length / GALLERY_PAGE_SIZE,
      )}) - ${JSON.stringify(duplicates.current)}`,
    )
  }

  const isComponentCheck = (product: ProductGridItem) => {
    return 'component' in product ? product.component : ''
  }

  const currentTilesPerRow = useNumberOfTilesPerRow()

  const isGallery = true

  const shouldUseWideADTile = currentTilesPerRow === tilesPerRow.md
  /* istanbul ignore next */
  const promoCellWidth = shouldUseWideADTile
    ? AD_CELL_WIDTH_WIDE
    : AD_CELL_WIDTH

  const promoTileLocation = promoTile
    ? currentTilesPerRow * AD_ROW - promoCellWidth
    : 0

  const csatTileLocation = ShowCSATTile
    ? calculateCsatPosition({
        currentTilesPerRow,
        tilesPerRow,
        desktopRowPosition: DESKTOP_ROW_POSITION,
        mobileRowPosition: MOBILE_ROW_POSITION,
        products,
      })
    : undefined

  const productGridElements = formatGridLayout({
    products,
    promoTileLocation,
    csatTileLocation,
  })

  return (
    <StyledGrid
      gap={/* istanbul ignore next */ shouldShowNewGridLayout ? 4 : true}
      shouldShowNewGridLayout
    >
      {productGridElements.map((currentProduct, index) => {
        const isFavourited = checkFavourited(
          favouritedProducts,
          currentProduct as ProductTileProduct,
        )
        /* istanbul ignore next */
        const productIndex = currentProduct.productIndex
          ? currentProduct?.productIndex
          : index
        const isLastItem = index + 1 === products.length
        const isFirstItem = index === 0
        const isOffsetBorderItem = productIndex % GALLERY_PAGE_SIZE === 0
        const cardsReservedSpaceRatio = 3 / 4
        const cardsReservedSpaceHeight = '200px'

        const isComponent = 'component' in currentProduct

        const pageTileProps = {
          isCardProduct: isCard,
          id: currentProduct.id,
          product: currentProduct as ProductTileProduct,
          productIndex,
          totalNumberOfProducts,
          trackingData,
          onAddToBasket,
          onClick: onProductClicked,
          onFirstClick: onProductFirstClick,
          isLastItem,
          isFirstItem,
          isOffsetBorderItem,
          onEndApproaching,
          onTopApproaching,
          onOffsetBorderApproaching,
          isInternalUser,
          isFavourited,
          handleFavourite,
          isGallery,
          groupCardProject,
        }

        const getPromoTile = () => {
          /* istanbul ignore next */
          if (!promoTile) return null

          return showCardProductTileVariant && isCard ? (
            <PromoTileVariant {...promoTile} />
          ) : (
            <PromoTile {...promoTile} isCardGallery={isCard} />
          )
        }

        const getCardTile = () => {
          return showCardProductTileVariant ? (
            <TrackedProductTile
              {...pageTileProps}
              key={pageTileProps.id}
              showCardProductTileVariant={showCardProductTileVariant}
            />
          ) : (
            <AspectRatio
              data-testid="cards-aspect-ratio"
              ratio={cardsReservedSpaceRatio}
              style={{
                minHeight: cardsReservedSpaceHeight,
              }}
            >
              <TrackedProductTile {...pageTileProps} key={pageTileProps.id} />
            </AspectRatio>
          )
        }

        return (
          <Box
            key={`${currentProduct.id}-${productIndex}`}
            data-testid="web-grid-item-wrapper"
            className="web-grid-item-wrapper"
            data-product-id={currentProduct.id}
            width={{
              xs: calculateComponentWidth(
                isComponentCheck(currentProduct),
                tilesPerRow.xs,
              ),
              sm: calculateComponentWidth(
                isComponentCheck(currentProduct),
                tilesPerRow.sm,
              ),
              md: calculateComponentWidth(
                isComponentCheck(currentProduct),
                tilesPerRow.md,
              ),
              lg: calculateComponentWidth(
                isComponentCheck(currentProduct),
                tilesPerRow.lg,
              ),
              xl: calculateComponentWidth(
                isComponentCheck(currentProduct),
                tilesPerRow.xl,
              ),
              xxl: calculateComponentWidth(
                isComponentCheck(currentProduct),
                tilesPerRow.xxl,
              ),
            }}
          >
            {lastVisitedProductId === currentProduct.id && (
              <div
                data-testid="web-last-visited-product-id-marker"
                ref={lastVisitedProduct}
              />
            )}

            {isComponent ? (
              <>
                {promoTile &&
                  currentProduct.component === ProductGridComponent.promoTile &&
                  getPromoTile()}
                {ShowCSATTile &&
                  currentProduct.component ===
                    ProductGridComponent.csatTile && (
                    <CustomerSatisfactionTile />
                  )}
              </>
            ) : (
              <>
                {isCard ? (
                  getCardTile()
                ) : (
                  <TrackedProductTile {...pageTileProps} />
                )}
              </>
            )}
          </Box>
        )
      })}
    </StyledGrid>
  )
}
