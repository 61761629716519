import { useGetBreakpoint } from '@moonpig/web-shared-utils'
import { getTilesPerRow } from './getTilesPerRow'

export const useNumberOfTilesPerRow = () => {
  const breakpoint = useGetBreakpoint()

  const currentTilesPerRow = getTilesPerRow(breakpoint)

  return currentTilesPerRow
}
